import axios from 'axios';
import React, { useState } from 'react';
import Loader from "react-loader-spinner";
import { colors } from "../../utils/index";
import logo from '../../assets/logo.png';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import './Main.css';
const Registration = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone1, setPhone1] = useState('');
    const [colorError, setcolorError] = useState('red');
    const [popupOpenError, setPopupOpenError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [isLoading, setIsLoading] = useState(false);
  const widthScreen = window.innerWidth < 790;
  const handleRegister = () => {
    // Basic email validation using a regul
     setIsLoading(true)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setMensaje('Email Inválido. Por favor ingrese un email válido.')
        setPopupOpenError(true)
        setIsLoading(false)
      return;
    }

    // Basic password validation (minimum length)
      if (password.length < 6) {
        setMensaje('Contraseña Inválida. Debe teneral menos 6 caracteres')
        setPopupOpenError(true)
        setIsLoading(false)

      return;
      }
       const auth = getAuth();
     createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Registro exitoso
          const user = userCredential.user;
        console.log(user)
       const body = {
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        phone1: phone1,
        loginType:'own'
    };
    // Define the axios configuration
    const config = {
      method: 'post',
      url: 'https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerusersx', // Replace with your registration endpoint
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: body,
    };

    // Send the registration request
    axios(config)
      .then((response) => {
        if (!response.data.error) {
                setcolorError('green')
                setMensaje('Registración Exitosa')
                setPopupOpenError(true)
                setIsLoading(false)
          // You can navigate to a login screen or perform other actions here

        } else {

                setMensaje(response.data.error)
                setPopupOpenError(true)
                setIsLoading(false)
        }
      })
        .catch((error) => {
           setIsLoading(false);
        alert( error.message);
      });

        // Puedes realizar otras acciones después del registro exitoso
        // como redirigir al usuario a una página de inicio de sesión, etc.
      })
      .catch((error) => {
        // Manejar errores de registro
        setIsLoading(false);
        setcolorError('red');
        setMensaje(`Error en el registro: ${error.message}`);
        setPopupOpenError(true);
      });
    // Define the request body

  };
 const onClose = () => {
    localStorage.clear();
    localStorage.setItem('email', email);
     localStorage.setItem('username', firstName);

     if (email=== '') {
            window.location.href = "/login";
     } else {
          window.location.href = "/campaignspage";
     }

 }

const handlePopupCloseError = () => {
    //setPayEliminarNo(false)
    setPopupOpenError(false);
    setIsLoading(false);
    if (colorError === 'green') {
        onClose()
    } else {
        setcolorError('red')
    }
     };

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: widthScreen?'flex-start': 'center',
    marginTop: 5,
    paddingLeft: 20,
    width:'90vw'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    width: widthScreen? '100%': '60%',
    marginBottom: 10,
  },
  label: {
    flex: '20%', // Ocupa el 20% del ancho
    fontSize: '0.9em',
    color: '#fff',
    marginLeft: 5,
    marginRight: 10,
    textAlign:'left'

  },
  input: {
    flex: '80%', // Ocupa el 80% del ancho
    marginBottom: 10,
    height: 35,
    fontSize: '0.9em',
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 30,
    textTransform: 'uppercase',
    border:'none'
  },
  button: {
    marginTop: 35,
    height: 35,
    fontSize: '1.1em',
    border: 'none',
    width: 150,
    color: colors.WHITE,
    backgroundColor: colors.ORANGE,
    borderRadius: 15,
    marginRight: 10,
    cursor:'ponter'
    },


};


  return (
     <div className="home-page" style={{marginBottom:0}}>
        <div style={{ backgroundColor: colors.GRAY, backgroundSize: 'cover', height: '100vh' }}>

        <header style={{ textAlign: 'center', marginTop: '10%' }}>
          <img src={logo} alt="Logo" style={{ width: '30%' }} />
        </header>
        <header style={{ textAlign: 'center' }}>
          <p className="titleDetail" style={{ color: colors.ORANGE, fontSize: '1.9em', marginTop: '1%', fontWeight:'bold'}}>REGISTER</p>
        </header>

         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {isLoading && <Loader type="TailSpin" color="#fff" height={80} width={80}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  position:'absolute', marginTop:'50%'}} />}
          <div style={styles.container}>
            <div style={styles.row}>
              <label style={styles.label}>Email:</label>
              <input style={styles.input}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>Password:</label>
             <input style={styles.input}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>First Name:</label>
              <input style={styles.input}
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your first name"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>Last Name:</label>
               <input style={styles.input}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>Phone 1:</label>
               <input style={styles.input}
                type="text"
                value={phone1}
                onChange={(e) => setPhone1(e.target.value)}
                placeholder="Enter your phone number"
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , textAlign:'center', justifyContent:'center', alignContent:'center', alignSelf:'center'}}>
                <button onClick={handleRegister} style={styles.button}>Register</button>
                <button onClick={onClose} style={styles.button}>Close</button>
            </div>
          </div>
        </div>
          </div>
              {popupOpenError && (
                    <div style={{ position: 'absolute', top: 0,  width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{width:'80%', backgroundColor: colorError, padding: '20px', borderRadius:10, maxWidth: '300px', height:250 }}>
                        <p style={{fontFamily:'Como-Bold',marginTop:'20%', alignSelf:'center', justifyContent:'center', alignItems:'center', textAlign:'center', color:'white',  fontSize:  '1em', fontWeight:'bold'}}>{mensaje}</p>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
                            <div>
                                <button style={{
                                        ...styles.button,
                                        backgroundColor: colors.BLACK,
                                        color: colors.WHITE
                                    }}
                                    onClick={handlePopupCloseError}
                                    >
                                    OK
                                </button>
                                </div>
                        </div>
                    </div>

                    </div>
                )}
    </div>
  );
};


export default Registration;