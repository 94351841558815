import React, { useState ,useEffect,useRef} from 'react';
import { useHistory, Link } from 'react-router-dom';
import Header from './Header';
import { colors } from '../../utils/index';
import Loader from 'react-loader-spinner';
import axios from 'axios';

import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'

const Resumen = ({ location }) => {
    const [preferenceId, setPreferenceId] = useState(null);
     // eslint-disable-next-line
    const history = useHistory();

    const [popupOpenError, setPopupOpenError] = useState(false);
    const [mensaje, setMensaje] = useState('');
        const [isLoading, setIsLoading] = useState(false);
        // eslint-disable-next-line
    const [colorError, setcolorError] = useState('red');
    const [showWalletButton, setShowWalletButton] = useState(false);
    const isMountedRef = useRef(true);
    const widthScreenMobile = window.innerWidth < 400
    const campaignData = JSON.parse(localStorage.getItem('campaignData'));
    const accessToken =  campaignData.publickey_MP

    localStorage.setItem('token_MP',campaignData.token_MP)
   useEffect(() => {
    // Verifica si no hay un valor en localStorage.email y redirige al login
    if (!localStorage.email) {
      history.push('/login');
    } else {
        initMercadoPago(`${accessToken}`);

    }
  }, [history,accessToken]);

useEffect(() => {
    const initializeMercadoPago = async () => {
      try {
          setIsLoading(true);
            await new Promise(resolve => setTimeout(resolve, 2000));
        // Inicializa MercadoPago

        // Crea la preferencia y obtén el preferenceId
        const id = await createPreference();
        await setPreferenceId(id);

     if (isMountedRef.current) {
            await handlePayment(id);
            //setShowWalletButton(true);
            setIsLoading(false);
            //console.log('Preference ID:', id);

      }
      } catch (error) {
        console.error('Error al inicializar MercadoPago:', error);
        alert('Error al inicializar MercadoPago. Por favor, intenta de nuevo.');
        if (isMountedRef.current) {
        setIsLoading(false);
        }
      }
    };

    initializeMercadoPago();
      return () => {
     isMountedRef.current = false;
  };
  }, []);


  const createPreference = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      const body = {
        title: localStorage.name + "*" + localStorage.date,
        email: localStorage.email,
        quantity: 1,
        price: parseFloat(localStorage.importe),
        first_name: localStorage.username,
        name: localStorage.name,
        date: localStorage.date,
        category_id: localStorage.prize_name,
        prize_name: localStorage.prize_name,
        token_MP:`${localStorage.token_MP}`
      };

      const response = await axios.post('https://wyzuwbkhwf.execute-api.us-east-1.amazonaws.com/prod/create_preference', body, { headers });
      const { id } = response.data;
     // console.log('Preference ID:', response.data);
      return id;
    } catch (error) {
      console.error('Error al crear preferencia:', error);
      alert('Error al crear preferencia. Por favor, intenta de nuevo.');
      throw error;
    }
  };


 const handlePopupCloseError = () => {
    if (mensaje === 'Registración Exitosa') {
      setShowWalletButton(true);
    }
    setPopupOpenError(false);
  };

// eslint-disable-next-line
    const handlePayment = async (id) => {

    try {
        const body = {
        preference_id: id,
        email: localStorage.email,
        date: localStorage.date,
        name: localStorage.name,
        importe:localStorage.importe,
        name_prize: localStorage.prize_name,

      };
      //  console.log('acac body',body)
      // Define the axios configuration
      const config = {
        method: 'post',
        url: 'https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerDonaciones', // Replace with your registration endpoint
        headers: {
          'Content-Type': 'application/json',
        },
        data: body,
      };

      // Send the registration request
      await axios(config)
          .then((response) => {

              if (!response.error) {
                setMensaje('Registración Exitosa');
                setShowWalletButton(true)
                setIsLoading(false);
            // You can navigate to a login screen or perform other actions here
          } else {
            setMensaje(response.data.error);
            setPopupOpenError(true);
            setShowWalletButton(false)
            setIsLoading(false);
          }
        })
          .catch((error) => {
              setIsLoading(false);
              alert(error.message);

        });
    } catch (error) {
      // Handle errors
            console.error('Error en el manejo de pago:', error);
            setIsLoading(false);
            alert('Error al procesar el pago. Por favor, intenta de nuevo.');
    }
  };


    const buttonStyle = {
  marginBottom: 5,
  height:widthScreenMobile? 40: 50,
  fontSize: widthScreenMobile?'1em': '1.3em',
  border: 'none',
  width: widthScreenMobile? '150px': '200px',
  color: 'white',
  backgroundColor: colors.ORANGE,
  borderRadius: '50px 0px 50px 0px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  marginTop: '5px',
};

const ContainBox = {
  height:widthScreenMobile?40: 60,
  fontSize: widthScreenMobile?'0.9em': '1.2em',
  border: 'none',
width: widthScreenMobile? '250px': '300px',
  color: 'white',
  fontWeight: '900',
  fontFamily: 'Poppins-Black',
  backgroundColor: colors.BLUE,
  borderRadius: '30px 0px 50px 30px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  marginTop: '-20px',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.6)',
  textAlign: 'center'
};

const titelBox = {
  textAlign: 'center',
  fontStyle: 'italic',
  fontFamily: 'Poppins-Black',
  color: colors.ORANGE,
  fontSize: '1.3em',
};



  return (
    <div style={{ fontFamily: 'Roboto-Regular', backgroundColor: colors.GRAY, height: '100vh', flex: 1, alignItems: 'center', backgroundSize: '100vw 100vh', flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
      <Header showTitle="resumen" />

      <div style={{ textAlign: 'center', marginTop: '70px', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                  <Link
                      to={{
                          pathname: `/donate/${localStorage.name}`,
                          state: {
                              name: localStorage.name,
                              date: localStorage.date,
                              prize_name: localStorage.prize_name,
                              image: localStorage.image,
                          },
                  }}

                  disabled={showWalletButton}
                      style={{ color: colors.ORANGE, fontSize: '3em', textDecoration: 'none', marginLeft: '10px', display: 'block', }}
                  >
                      {'<'}
                  </Link>

        {/*<div style={{ display: 'flex', textAlign: 'right' }}>
          <Link to="/gracias">
            <img src={faq} alt="Logo" style={{ width: '30%', marginRight: '10px', marginTop: '10px' }} />
          </Link>
        </div>*/}
          </div>

         <div style={{ marginTop:'-20px', fontWeight: 'bold', flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>
         <div style={{
                width: '90%', backgroundColor:colors.WHITE, borderRadius: '50px 0px 50px 50px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',maxWidth:widthScreenMobile? '300px': '500px',
                fontWeight: 'bold', flex: 1, display: 'flex', justifyContent: 'center', marginBottom: '5px', flexDirection: 'column', textAlign: 'center', alignContent: 'center', alignItems: 'center'}}>
                <p style={{...titelBox}}>CAUSA</p>
                <p style={{...ContainBox}}> #{localStorage.name}</p>
                <p style={{...titelBox}}>IMPACTO</p>
                <p style={{ ...ContainBox }}> ${localStorage.importe}</p>
                <p style={{...titelBox}}>INCENTIVO</p>
                <p style={{ ...ContainBox }}> {localStorage.prize_name}</p>

             </div>


</div>

 <p style={{...titelBox}}>Impactá con</p>

            <div id="wallet_container" style={{ position: 'absolute',  left: 0, right: 0, display: 'flex', justifyContent: 'center', marginTop:'-15px' }}>
                {showWalletButton && preferenceId &&(
                    <Wallet initialization={{ preferenceId: preferenceId }} />
                )}
            </div>

      {isLoading &&  (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
          <Loader type="TailSpin" color="#000" height={80} width={80} />
        </div>
      )}
       {popupOpenError && (
                    <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '80%', backgroundColor: colorError, padding: '20px',borderRadius: '50px 0px 50px 50px',maxWidth: '300px', height: 250 }}>
                        <p style={{fontFamily:'Como-Bold', marginTop: '20%', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'white', fontSize: '1.5em' }}>{mensaje}</p>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
                        <div>
                            <button style={{
                            ...buttonStyle,
                            backgroundColor: colors.BLACK,
                            color: colors.WHITE, fontSize: '1.5em',
                            }}
                            onClick={handlePopupCloseError}
                            >
                            OK
                            </button>
                        </div>
                        </div>
                    </div>
                    </div>
                    )}
    </div>
  );
};


export default Resumen;