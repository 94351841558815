import React, { useState } from 'react';
    // eslint-disable-next-line
import { useHistory } from 'react-router-dom';
import Header from './Header';
import { colors } from '../../utils/index';
import Loader from 'react-loader-spinner';
import logo from  '../../assets/logo.png';
import Background from '../../assets/fondoiniciosesion.png'


const Failure = () => {

        // eslint-disable-next-line
    const history = useHistory();
    const heightScreen = (window.innerHeight > 680)
    const widthScreen = (window.innerWidth < 790)
// eslint-disable-next-line

    const [popupOpenError, setPopupOpenError] = useState(false);
    // eslint-disable-next-line
    const [mensaje, setMensaje] = useState('');
    const [isLoading, setIsLoading] = useState(false);



  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setIsLoading(false);
  };
    const handleContinue = () => {

   history.push({
                pathname: '/login'
            });
  };



  return (
    <div style={{fontFamily:'Como-Bold',backgroundImage:  `url(${Background})`, height: '100vh', flex:1, alignItems: 'center' , backgroundSize:'cover', flexDirection:'row', alignSelf:'center', justifyContent:'center', alignContent:'center', textAlign:'center'}}>
           <Header showTitle="" />
            <div style={{textAlign:'left', marginTop: '70px', marginLeft: '10px'}}>
            </div>
            <img src={logo} alt="Logo" style={{ width: '150px',marginTop: widthScreen ? heightScreen? '55%':'25%' : '10%'  }} />
             <h1 style={{fontFamily:'Como-Bold', color: colors.WHITE, marginBottom: '10px',fontSize:'1.2.em', marginTop:'15px' }}>Lamentablemente</h1>
             <h1 style={{ color: colors.WHITE, marginBottom: '10px',fontSize:'1.2.em', marginTop:'2px' }}>{localStorage.username}</h1>
            <div style={{ position: 'relative', marginTop:'5px', marginBottom: '2px' }}>
              <p style={{ fontSize: '0.9em', color: colors.WHITE, margin: '0' }}>HUBO UN ERROR EN EL PAGO</p>
               <p style={{fontSize:'0.9em', color: colors.WHITE, margin: '0' }}> </p>
            </div>


            <div style={{ position: 'relative', marginBottom: '20px', marginTop:'50px',  }}>
                <p style={{fontSize:'0.9em',color: colors.WHITE, margin: '0' }}>INTÉNTALO NUEVAMENTE</p>
                <p style={{fontSize:'0.9em',color: colors.WHITE, margin: '0' }}></p>
            </div>

            <button  style={{
                            marginBottom: 25,
                            marginTop:'40px',
                            height: 50,
                            fontSize: '1.1em',
                            border: '1px solid ' + colors.ORANGE, // Set border to 1px solid orange
                            width: '200px',
                            color: colors.WHITE,
                            backgroundColor: colors.ORANGE,
                            borderRadius: '50px 0px 50px 0px',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            textDecoration: 'none',
                            margin: '0 auto',
                            fontWeight:'bold'
                        }}
                        onClick={handleContinue}
                    >
                    VOLVER
              </button>

     {isLoading && <Loader type="TailSpin" color="#fff" height={80} width={80} />}
      {popupOpenError && (
        <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '80%', backgroundColor: 'red', padding: '20px', borderRadius: 10, maxWidth: '300px', height: 250 }}>
            <p style={{ fontFamily:'Como-Bold',marginTop: '20%', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'white', fontSize: '1em', fontWeight: 'bold' }}>{mensaje}</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
              <div>
                <button style={{
                  ...buttonStyle,
                  backgroundColor: colors.BLACK,
                  color: colors.WHITE,
                }}
                  onClick={handlePopupCloseError}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
          )}



    </div>
  );
};

const buttonStyle = {
  marginBottom: 25,
  height: 60,
  fontSize: '1.3em',
  border: 'none',
  width: '100%',
  color: 'white',
  backgroundColor: colors.ORANGE,
  borderRadius: 20,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  marginTop: '10px',
};

export default Failure;
