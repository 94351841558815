// src/App.js
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// eslint-disable-next-line
import Home from './components/client/Home';
import Campaign from './components/client/Campaign';
import CampaignsPage from './components/client/CampaignsPage';
import Login from './components/client/Login';
import Registration from './components/client/Registration';
import Premios from './components/client/Premios';
import Donate from './components/client/Donate';
import Resumen from './components/client/Resumen';
import Gracias from './components/client/Gracias';
import Failure from './components/client/Failure';

function App() {
  return (
    <Router>
      <div>
        <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/registration" component={Registration} />
        <Route path="/campaignspage" component={CampaignsPage} />
        <Route path="/campaign" component={Campaign} />
        <Route path="/premios" component={Premios} />
        <Route path="/donate" component={Donate} />
        <Route path="/resumen" component={Resumen} />
        <Route path="/gracias" component={Gracias} />
        <Route path="/failure" component={Failure} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
