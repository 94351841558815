import React, { useState, useEffect } from 'react';
import Header from './Header';
import { colors } from '../../utils/index';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom'
import ProgressBar from './ProgressBar';
import igImg from '../../assets/instagram.png'
import { useHistory } from 'react-router-dom';
import botonGenerar from '../../assets/botones/botonGenerar.png'
import './Main.css'
const Campaign = ({ location }) => {
    const { name, subtitle, description, image, date, igLink } = location.state;

// eslint-disable-next-line
        const [campaigns, setCampaigns] = useState([]);
        const [popupOpenError, setPopupOpenError] = useState(false);
        const [mensaje, setMensaje] = useState('');
        const [totalCampaign, setTotalCampaign] = useState('');
        const [totalCampaignNeed, setTotalCampaignNeed] = useState('');
        const widthScreen =  window.innerWidth > 790
        const [isLoading, setIsLoading] = useState(false);
        const history = useHistory();
        const [percentage, setPercentage] = useState(0);

  const handlePopupCloseError = () => {
        setPopupOpenError(false);
        setIsLoading(false);
        history.push({
                pathname: '/campaignspage'
            });
  };


    useEffect(() => {
    setIsLoading(true);
    const requestBody = {name_campaign: name, date: date};

    const fetchCampaigns = async () => {
      try {
        const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultTotalCampaign', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          setMensaje('ERROR INICIO DE SESION');
          setPopupOpenError(true);
            setIsLoading(false);
             history.push({
                pathname: '/campaign'
            });
        }

          const data = await response.json();

          if ((parseFloat(data.data.totalCampaignNeed)  - parseFloat( data.data.totalCampaign)) === 0) {
                setMensaje('CAMPAÑA LOGRADA!');
                setPopupOpenError(true);
              setIsLoading(false);
              return
          }
        const calculatedPercentage = data.data.totalCampaignNeed !== 0
            ? ((100 - (data.data.totalCampaign / data.data.totalCampaignNeed) * 100).toFixed(2))
            : 0;
          setTotalCampaign(data.data.totalCampaign);
          setTotalCampaignNeed(data.data.totalCampaignNeed);
          localStorage.setItem('totalCampaign',data.data.totalCampaign )
          localStorage.setItem('totalCampaignNeed', data.data.totalCampaignNeed)
        //  console.log(data.data.totalCampaign,data.data.totalCampaignNeed)
          setIsLoading(false);
          setPercentage(calculatedPercentage);
      } catch (error) {
          console.error('Error fetching campaign data:', error.message);
           setIsLoading(false);
           alert('Error Red Inestable')
      }
    };

    fetchCampaigns();
 }, [date, name, history]);


  return (
    <div style={{ fontFamily:'Como-Bold', backgroundColor: colors.GRAY, minHeight: '100vh', flex:1, alignItems: 'center' , backgroundSize:'100vw 100vh', flexDirection:'column', alignSelf:'center', justifyContent:'center', alignContent:'center', textAlign:'center'}}>
          <Header showTitle="causas" />
           <div style={{ textAlign: 'center', marginTop: '70px', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Link to="/campaignspage"  style={{ color: colors.ORANGE, fontSize: '3em', textDecoration: 'none', marginLeft: '20px', display: 'block'}}>
                        {'<'}
              </Link>
               {/*<div style={{ display: 'flex', textAlign:'right' }}>
                    <Link to="/failure">
                        <img src={faq} alt="Logo" style={{ width: '30%', marginRight: '10px' , marginTop:'10px'}} />
                    </Link>
                </div>*/}
        </div>

        <div style={{ flex: 1,display:'flex', flexDirection:'row', textAlign:'center', justifyContent:'center', alignContent:'center', alignItems:'center' }}>
                <div style={{ width: widthScreen ? '70%' : '90%', maxWidth:widthScreen?'800px': '',
                    textAlign: 'center',height: '90%', border: '2px solid ' + colors.ORANGE, borderRadius: '80px 0px 80px 80px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)'}}>

                    <img src={image} alt={name} style={{
                       width: widthScreen ? '70%' : '95%',margin: '10px',
                        borderRadius: '80px 0px 0px 0px'
                        }} />
                    <h2 style={{fontFamily:'Como-Bold' ,color: colors.WHITE, margin: '0' }}>#{name}</h2>
                    <h3 style={{ fontFamily:'Como-Medium',color: colors.WHITE, margin: '0', marginBottom:'20px' }}>{subtitle}</h3>
                    <p style={{ fontSize:widthScreen?'1.2em':'1em', color: colors.GRAYL, margin: '10px', textAlign: 'justify', padding: 2 , marginBottom:'50px'}}>{description}</p>
                 </div>
        </div>

        <Link
            to={{
                pathname: `/premios/${name}`,
                state: {
                name: name,
                subtitle: subtitle,
                description: description,
                image: image,
                date: date,
                igLink: igLink
                },
            }}
            >
            <img
                key={name}
                src={botonGenerar}  // Reemplaza 'ruta_de_tu_imagen' con la ruta de tu imagen
                alt="Texto alternativo"
                style={{
                height: 'auto',
                marginTop:'20px',
                width: '300px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
            />
            </Link>

         <div style={{ marginTop:widthScreen ? '5px':'5px' }}>

              <span style={{
                fontFamily:'Como-Medium',
                color: colors.WHITE,
                margin: 0,
                fontSize: '1.1em',
                textDecoration: 'none',
                textAlign:'left'

              }}>ESTAMOS A </span>

            <span
            style={{
              fontFamily: 'Como-Bold',
              fontWeight: 'bold',
              color: colors.BLUE,
              margin: 5,
              fontSize: '2em',
              textDecoration: 'none',
            }}
          >
             {`${percentage} %`}

          </span>
              {/* Incorpora la barra de progreso */}
              <div style={{paddingTop:'20px', color:colors.WHITE, fontSize:'.9em', marginTop:'-10px'}}>
                <ProgressBar percentage={(totalCampaign / totalCampaignNeed) * 100} />
              </div>
             <p style={{
                fontFamily:'Como-Medium',
                color: colors.WHITE,
                fontSize: '1.1em',
                textDecoration: 'none',
                textAlign: 'center',
                marginTop:'10px', paddingRight:10

              }}>DE CUMPLIR EL OBJETIVO</p>


                <div style={{flex:1, display:'flex',marginTop:'-20px', flexDirection:'row', width:'100vw',textAlign:'center', justifyContent:'center'}}>
                    <div style={{width:'300px',
                        fontSize: '1em', color: colors.WHITE,
                        fontFamily:'Como-Bold', justifyContent:'center', textAlign:'center', alignItems:'center', alignSelf:'center', alignContent:'center'
                        }}>
                        <a  href={igLink} target="_blank" rel="noopener noreferrer" style={{marginLeft:'10px',marginRight: '5px', textDecoration:'none', color:'#fff' , cursor:'pointer'}}>
                            PARA CONOCER MÁS CAUSAS A LAS QUE APOYAMOS, CLICK ACÁ
                        </a>

                    </div>
                    <a href={igLink} target="_blank" rel="noopener noreferrer">
                        <img src={igImg} alt={name} style={{ width: widthScreen ? '60%' : '70%',marginTop:'10px'}} />
                    </a>
                </div>



          </div>



     {isLoading && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
          <Loader type="TailSpin" color="#000" height={80} width={80} />
        </div>
      )}
      {popupOpenError && (
        <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '80%', backgroundColor: 'red', padding: '20px',borderRadius: '50px 0px 50px 50px',maxWidth: '300px', height: 250 }}>
            <p style={{fontFamily:'Como-Bold', marginTop: '20%', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'white', fontSize: '1.5em' }}>{mensaje}</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
              <div>
                <button style={{
                  ...buttonStyle,
                  backgroundColor: colors.BLACK,
                  color: colors.WHITE, fontSize: '1.5em',   borderRadius: '50px 0px 50px 0px',
                }}
                  onClick={handlePopupCloseError}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
          )}



    </div>
  );
};

const buttonStyle = {
  height: 50,
  fontSize: '2em',
  border: 'none',
  width: '250px',
  color: 'white',
  fontWeight: '900',
  fontFamily:'Poppins-Black',
  backgroundColor: colors.BLUE,
  borderRadius: '30px 0px 50px 30px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  marginTop: '20px',
  marginBottom: '20px',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.6)',
};

export default Campaign;
