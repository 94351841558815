import React, { useState, useEffect } from 'react';
import Header from './Header';
import { colors } from '../../utils/index';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import './Main.css';
import igImg from '../../assets/instagram.png'
import botonContinuar from '../../assets/botones/botonContinuar.png'
const Premios = ({ location }) => {
    // eslint-disable-next-line
  const { name, subtitle, description, image, date, prize_name , igLink, publickey_MP} = location.state;
  const [premios, setPremios] = useState([]);
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [selectedLinkPrize, setSelectedLinkPrize] = useState(null);

  const widthScreenPC = window.innerWidth > 900
  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setIsLoading(false);
  };

  useEffect(() => {
    const requestBody = { name_campaign: name };

    const fetchCampaigns = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/CONSULTPRIZE', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        if (data.error) {
            setMensaje('NO TIENE PREMIOS TODAVÍA');
            setPopupOpenError(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setPremios(data);
        }

      } catch (error) {
          console.error('Error fetching campaign data:', error.message);
           setIsLoading(false);
           alert('Error Red Inestable')
      }
    };

    fetchCampaigns();
  }, [date, name]);

  return (
    <div style={{ fontFamily: 'Roboto-Regular', backgroundColor: colors.GRAY, height: '100vh', flex: 1, alignItems: 'center', backgroundSize: 'cover', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
      <Header showTitle="premios" />
      <div style={{ textAlign: 'left', marginLeft: '10px',paddingTop:'70px' }}>

        <div style={{ textAlign: 'center', marginTop: '10px', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <Link
                    to={{
                        pathname: `/campaign/${name}`,
                        state: {
                        name: name,
                        subtitle: subtitle,
                        description: description,
                        image: image,
                        date: date,
                        igLink:igLink
                        },
                    }}
                    style={{  color: colors.ORANGE, fontSize: '3em', textDecoration: 'none', marginLeft: '10px', display: 'block' }}
                    >  {'<'}
              </Link>

        </div>

      </div>
      <h1 style={{fontFamily:'Poppins-Black', fontStyle: 'italic' , marginTop: '-40px', color: colors.BLUE }}>POTENCIA</h1>
      <div style={{ width: '90%', textAlign: 'center', margin: 'auto', marginBottom: '2px',justifyContent: 'center',  maxWidth:'800px'}}>
        <p style={{ fontFamily:'Como-Medium',fontSize:'1.3em', color: colors.WHITE, margin: '0', textAlign: 'center', width: '100%' }}>Estás muy cerca de generar impacto. Desde G&W queremos incentivar la generosidad de nuetros givers. Elegí el incentivo por el cual queres participar</p>
          </div>
          {Array.isArray(premios) && premios.length > 0 && (
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignContent: 'center', textAlign: 'center', flexDirection: 'column', alignItems: 'center', height:widthScreenPC? '50vh': '' }}>
                  {premios.map((premio, index) => (
                      <button
                          key={`${premio.name}_${index}`}
                          style={{
                              height: 50,
                              fontSize: '1.2em',
                              border: '1px solid ' + (selectedPrize === premio.name ? colors.ORANGE : colors.BLUE),
                              width: '300px',
                              color: 'white',
                              backgroundColor: selectedPrize === premio.name ? colors.ORANGE : colors.BLUE,
                              borderRadius: '20px 0px 50px 20px',
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              textDecoration: 'none',
                              marginTop: '10px',
                              textTransform: 'uppercase',
                              marginBottom: '5px',
                              fontFamily: 'Como-Heavy',
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 1)',
                              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

                          }}
                          onClick={() => setSelectedPrize(premio.name) & setSelectedLinkPrize(premio.idLinkPrize)}
                      >
                          {premio.name}
                      </button>
                  ))}
              </div>
          )}
  <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', textAlign: 'center'}}>
            <Link
                to={{
                pathname: selectedPrize !== null ? `/donate/${selectedPrize}` : '/premios',
                state: {
                    name: name,
                    subtitle: subtitle,
                    description: description,
                    image: image,
                    date: date,
                    prize_name: selectedPrize,
                    igLink: igLink,
                    idLinkPrize: selectedLinkPrize,
                    publickey_MP:publickey_MP
                },
                }}

              >
                    <img
                key={name}
                src={botonContinuar}  // Reemplaza 'ruta_de_tu_imagen' con la ruta de tu imagen
                alt="Texto alternativo"
                style={{
                height: 'auto',
                width: '250px',
                marginTop:'30px',
                borderRadius: 30,
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
            />

            </Link>
             <div style={{flex:1, display:'flex', flexDirection:'row', width:'100vw',textAlign:'center', justifyContent:'center',  position: 'fixed',
                    bottom: 0,}}>
                    <div style={{width:'300px',
                    fontSize: '1em', color: colors.WHITE,
                    fontFamily:'Como-Bold', justifyContent:'center', textAlign:'center', alignItems:'center', alignSelf:'center', alignContent:'center', cursor:'pointer'
                        }}>
                        <a  href={igLink} target="_blank" rel="noopener noreferrer" style={{marginLeft:'10px',marginRight: '5px', textDecoration:'none', color:'#fff' }}>
                            PARA CONOCER MÁS CAUSAS A LAS QUE APOYAMOS, CLICK ACÁ
                        </a>

                    </div>
                     <a href={igLink} target="_blank" rel="noopener noreferrer">
                        <img src={igImg} alt={name} style={{ width: '60%',  margin: '10px' }} />
                    </a>
                </div>
        </div>
      {isLoading && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
          <Loader type="TailSpin" color="#000" height={80} width={80} />
        </div>
      )}
      {popupOpenError && (
        <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '80%', backgroundColor: 'red', padding: '20px', borderRadius: 10, maxWidth: '300px', height: 250 }}>
            <p style={{fontFamily:'Como-Bold', marginTop: '20%', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'white', fontSize: '1em', fontWeight: 'bold' }}>{mensaje}</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
              <div>
                <button
                  style={{
                    ...buttonStyle,
                    backgroundColor: colors.BLACK,
                    color: colors.WHITE,
                  }}
                  onClick={handlePopupCloseError}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const buttonStyle = {
  marginBottom: 25,
  fontSize: '1.3em',
  border: 'none',
  color: 'white',
  borderRadius: 20,
  cursor: 'pointer',
  textDecoration: 'none',
  marginTop: '10px',
};

export default Premios;
