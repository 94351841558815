import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBukZKxozYqM-ptABngVUhCidOg_wTUTjw",
  authDomain: "giveandwin-a02d4.firebaseapp.com",
  projectId: "giveandwin-a02d4",
  storageBucket: "giveandwin-a02d4.appspot.com",
  messagingSenderId: "190387442038",
  appId: "1:190387442038:web:c3449c2957d2aa5c886a57",
  measurementId: "G-HQ2211FP23"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
