// ProgressBar.js
import React from 'react';
import { colors } from '../../utils/index';
const ProgressBar = ({ percentage }) => {
    let validPercentage = isNaN(percentage) ? 0 : percentage;
    let widthpercent = 0
    // eslint-disable-next-line
    let borderR =  '0px 0px 90px 0px'
    // Calcula el ancho de la barra de progreso
    let percentBar = (validPercentage * 300) / 100
        //console.log(percentBar)
    if (percentBar > 300){
        widthpercent = 300
        // eslint-disable-next-line
        borderR = '0px 0px 90px 0px'

    } else {
        widthpercent = percentBar
    }

    //if (percentBar < 40 ) {
    //    widthpercent = 10
    //    borderR = '0px 0px 90px 0px'
    //}

  const containerStyle = {

                height: 50,
                fontSize: '1.1em',
                width: '300px',
                backgroundColor: '#fff',
                borderRadius: '0px 0px 90px 0px',
                cursor: 'pointer',
                textDecoration: 'none',
                marginTop: '10px',
                margin: '0 auto',
                fontWeight: 'bold'
                }


  const percentageStyle = {

                height: 50,
                fontSize: '1.1em',
                width: widthpercent,
                color: colors.BLUE,
                backgroundColor: colors.BLUE,
                borderRadius: borderR,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                textAlign: 'left',
                textDecoration: 'none',
                fontWeight: 'bold'
                }
  return (
    <div style={containerStyle}>
      <span style={percentageStyle}></span>
    </div>
  );
};

export default ProgressBar;