import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import logo from '../../assets/logo.png';
import './Header.css';
import { colors } from '../../utils/index';
import causas from '../../assets/titulo_causas.png'
import premios from '../../assets/titulos/Premios.png'
import donar from '../../assets/titulos/Donar.png'
import resumen from '../../assets/titulos/Resumen.png'
const Header = ({ showCircleButton, showTitle }) => {
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const widthScreen = window.innerWidth > 790;

  const toggleMobileMenu = () => {
      setIsMenuOpen(!isMenuOpen);

  };

  const handleNavItemClick = (page) => {
      setCurrentPage(page);
      setIsMenuOpen(false);
  };

const handleSalirClick = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        setIsMenuOpen(false);
      }
    };

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
    let showImage = causas
    if (showTitle === 'causas'){
        showImage = causas
    } else if  (showTitle === 'premios') {
        showImage = premios
     } else if  (showTitle === 'donar') {
        showImage = donar
    }else if  (showTitle === 'resumen') {
        showImage = resumen
    }
  return (
    <div style= {{backgroundColor: colors.GRAY, width:'100vw', height:'2px'}}>
      <header >
        <div className="header">
          {/* Icono de hamburguesa para dispositivos móviles */}
          <div style={{ left: 30 }} className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
            {widthScreen ? (
              <div style={{ fontSize: '1.3em', fontWeight: 'bold' }}>MENU</div>
            ) : (
              <>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
              </>
            )}
          </div>

          {/* Título */}
          <div  style={{ textAlign:'center'}}>
            {showTitle && <img src={showImage} alt="Header pic" style={{ width: '180px', height: 'auto', marginTop: '20px' , maxWidth:'250px'}} />}
          </div>
          <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', marginRight: '20px', marginTop:'-50px' }}>
            {/*<Link to="/">
              <img src={logo} alt="Logo" style={{ maxHeight: '50px', marginTop: '5px' }} />
            </Link>*/}
          </div>
          {/* Menú */}
          <div className={`nav ${isMenuOpen ? 'open' : ''}`} style={{ width: widthScreen ? '20%' : '60%' }}>
            <ul className="nav-list">

              <li className="nav-item">
                <Link
                  to="/campaignspage"
                  onClick={() => handleNavItemClick("/")}
                  className={currentPage === "/" ? "label-header-selected" : "label-header-not-selected"}
                >
                  CAUSAS
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/login"
                   className={currentPage === "/" ? "label-header-selected" : "label-header-not-selected"}
                    onClick={() => handleSalirClick()}>
                  SALIR
                </Link>
              </li>
            </ul>
          </div>

          {/* Logo en el margen derecho */}

        </div>
      </header>
    </div>
  );
};

export default Header;
