import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { colors } from '../../utils/index';
import Header from './Header';
import { useHistory, Link } from 'react-router-dom';
import './Main.css'
const CampaignsPage = () => {
    const history = useHistory();
    const [campaigns, setCampaigns] = useState([]);
    const [popupOpenError, setPopupOpenError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const widthScreen = window.innerWidth > 790


    useEffect(() => {
    // Verifica si no hay un valor en localStorage.email y redirige al login
    if (!localStorage.email) {
      history.push('/login');
    } else {
      const requestBody = {};
      setIsLoading(true);

      const fetchCampaigns = async () => {
        try {
          const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultCampaigns', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });
          const data = await response.json();


                if (data.error) {
                    setMensaje(data.error);
                    setPopupOpenError(true);
                } else {
                    if (Array.isArray(data)) {
                        setCampaigns(data);
                    } else {
                        console.error('Data is not an array:', data);
                        setMensaje('NO EXISTEN CAMPAÑAS');
                        setPopupOpenError(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching campaign data:', error.message);
                setMensaje('Error fetching campaign data. Please try again.');
                setPopupOpenError(true);
            } finally {
                setIsLoading(false);
            }
        };


      fetchCampaigns();
    }
  }, [history]);

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setIsLoading(false);
    history.push('/login');
  };



  const buttonStyle = {
    marginBottom: 25,
    height: 25,
    fontSize: '1.3em',
    border: 'none',
    width: 150,
    color: 'white',
    backgroundColor: colors.BLACK,
    borderRadius: 15,
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
  };

const handleCampaignClick = (campaign) => {
       localStorage.setItem('campaignData', JSON.stringify(campaign));

        // Redirigir a la página de detalle de la campaña
        history.push(`/campaign/${campaign.name}  state: {
              name: campaign.name,
              subtitle: campaign.subtitle,
              description: campaign.description,
              image: campaign.image1,
              date: campaign.date,
              igLink: campaign.igLink
            },`
        );
};


  return (
<div style={{ backgroundColor: colors.GRAY, width: '100vw', minHeight: '100vh', alignItems: 'center' }}>
  <Header showTitle="causas" />

  <div style={{ marginTop: widthScreen ? '70px' : '' }}>

    <div style={{ textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Link to="/login" style={{ color: colors.ORANGE, fontSize: '3em', textDecoration: 'none', marginLeft: '10px', display: 'block', marginTop: '70px' }}>
        {'<'}
      </Link>
    </div>

    <div style={{ marginTop: '20px', flex: 1, display: 'flex', justifyContent: 'center', alignContent: 'center', textAlign: 'center', flexDirection: 'column', alignItems: 'center', alignSelf: 'center' }}>

      {campaigns.map((campaign) => (
        <Link
          key={campaign.name}
          to={{
            pathname: `/campaign/${campaign.name}`,
            state: {
              name: campaign.name,
              subtitle: campaign.subtitle,
              description: campaign.description,
              image: campaign.image1,
              date: campaign.date,
              igLink: campaign.igLink
            },
          }}
           style={{
                width: widthScreen ? '90%' : '100%',
                textDecoration: 'none',
                color: 'inherit',
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
                 position: 'relative',
                 pointerEvents: campaign.completed === "completed" ? 'none' : 'auto',
           }}
        onClick={() => handleCampaignClick(campaign)}
        >
      <div style={{ border: '2px solid ' + colors.ORANGE, backgroundColor: '#000', borderRadius: '50px 0px 50px 50px', textAlign: 'center', width: widthScreen ? '70%' : '90%', margin: '0px', position: 'relative' }}>
        <img src={campaign.image1} alt={campaign.name} style={{height:'auto', width: widthScreen ? '70%' : '95%', borderRadius: '50px 0px 0px 0px', marginTop: '10px' }} />
        {campaign.completed === "completed" && (
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: colors.ORANGE, borderRadius: '50px 0px 50px 50px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: '1.5em', opacity: 0.7 }}>
                <p style={{color:colors.BLUE, fontSize:'2em', fontFamily:'Poppins-Black'}}>
                    ¡LOGRADO!
               </p>
            </div>
        )}
        <h2 style={{ color: 'orange', }}>#{campaign.name}</h2>
        <h3 style={{ color: '#fff', }}>{campaign.subtitle}</h3>
        <p style={{marginLeft:'20px',color: '#fff', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '1.5em', maxHeight: '4.5em', maxWidth:'280px' }}>{campaign.description}</p>
     </div>
        </Link>
      ))}
    </div>
  </div>

  {isLoading && (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
      <Loader type="TailSpin" color="#000" height={80} width={80} />
    </div>
  )}
  {popupOpenError && (
    <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ width: '80%', backgroundColor: 'red', padding: '20px', borderRadius: 10, maxWidth: '300px', height: 250 }}>
        <p style={{ fontFamily:'Como-Bold',marginTop: '20%', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'white', fontSize: '1em', fontWeight: 'bold' }}>{mensaje}</p>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
          <div>
            <button style={{
              ...buttonStyle,
              backgroundColor: colors.BLACK,
              color: colors.WHITE,
            }}
              onClick={handlePopupCloseError}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )}
</div>


  );
};

export default CampaignsPage;
