import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line
import logo from '../../assets/logo.png';

import btnGoogle from '../../assets/botongoogle.png'
// eslint-disable-next-line
import btnFacebook from '../../assets/botonfacebook.png'
// eslint-disable-next-line
import './Main.css'
import { colors } from "../../utils/index";

// eslint-disable-next-line
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider,signOut } from 'firebase/auth';
import { app } from '../../firebase';
// eslint-disable-next-line
import { OAuthProvider } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import Loader from "react-loader-spinner";
import Background from '../../assets/fondoiniciosesion.png'
const auth = getAuth(app);
// eslint-disable-next-line
const analytics = getAnalytics(app);

const Login = () => {
    // eslint-disable-next-line
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');
    // eslint-disable-next-line
    const [loginType, setLoginType] = useState('own');

    const [password, setPassword] = useState('');
    const [showForgotPassModal, setShowForgotPassModal] = useState(false);
    const [popupOpenError, setPopupOpenError] = useState(false);
    const [mensaje, setMensaje] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const widthScreen = (window.innerWidth < 790)

    const history = useHistory();

    //useEffect(() => {
    //    localStorage.clear(); // Clear localStorage before login

    //    const unsubscribe = auth.onAuthStateChanged((authUser) => {
    //        setIsLoading(true);

    //        if (authUser) {
    //            setUser(authUser);
    //            setPassword(authUser.uid);
    //            setEmail(authUser.email);

    //        } else {
    //            setLoginType("");
    //            setUser(null);
    //            setEmail('');
    //            setPassword('');
    //        }
    //        setIsLoading(false);
    //    });

    //    return () => {
    //        unsubscribe();
    //    };
    //}, []);



const handleToggleRegistration = () => {

    history.push({
                pathname: '/registration'
            });
  };
// eslint-disable-next-line
const guardarDatosUsuario = async (user) => {

};

const handlePopupCloseError = () => {
    //setPayEliminarNo(false)
    setPopupOpenError(false);
    setIsLoading(false);
     };


  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

      try {
        setIsLoading(true)

        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        setPassword(user.uid);
        setEmail(user.email);
      // Parse Google display name and update state
        const names = user.displayName.split(' ');
        const firstName = names[0];
        const lastName = names.slice(1).join(' '); // Join the rest as the last name
        handleLogin('google', user.email, user.uid ,firstName,lastName)
        //  history.push({
        //        pathname: '/campaignspage'
        //    });

      } catch (error) {
             setIsLoading(false)
            if (error.code === 'auth/popup-closed-by-user') {
            console.log('User closed the popup window');
            } else {
            console.error('Google Sign-In Error:', error);
            }
    }
  };



  const handleForgotPass = () => {
    setShowForgotPassModal(true);
  };

  const handleCloseModal = () => {
    setShowForgotPassModal(false);
  };


  const handleSubmit = (event) => {
    // Aquí puedes implementar la lógica para enviar un correo con el enlace para restablecer la contraseña.
    // Esto puede implicar una llamada a una API o servicio de correo electrónico.
      setEmail(event.target.value);
    // Después de enviar el correo, puedes cerrar el modal.
    handleCloseModal();
  };
// eslint-disable-next-line
const handleFacebookLogin = async () => {
        const provider = new FacebookAuthProvider();
    try {
            setIsLoading(true)
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            setPassword(user.uid);
            setEmail(user.email);
            const names = user.displayName.split(' ');
            const firstName = names[0];
            const lastName = names.slice(1).join(' '); // Join the rest as the last name
            handleLogin('facebook', user.email, user.uid, firstName,lastName)
            //history.push({
            //    pathname: '/campaignspage'
        //});
            console.log('acac user facebook', user)
    } catch (error) {
            setIsLoading(false)
            if (error.code === 'auth/popup-closed-by-user') {
            console.log('User closed the Facebook popup window');
            } else {

            console.error('Facebook Sign-In Error:', error);
            }
        }
    }

//  const handleAppleLogin = async () => {
//    const provider = new OAuthProvider('apple.com');
//    try {
//        await signInWithPopup(auth, provider);
//        console.log('acac user facebook', user)
//    } catch (error) {
//      console.error(error);
//    }
//  };


// eslint-disable-next-line
  const handleemailChange = (event) => {
    setEmail(event.target.value);
  };
// eslint-disable-next-line
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

 const handleLogin = async (loginType, email, password, first_name, last_name) => {
    try {
      if (email === '' || password === '') {
        return;
      }
      setIsLoading(true);

      if (loginType !== 'facebook' && loginType !== 'google') {
        loginType = 'own';
      }

      const requestBody = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
        loginType: loginType,
      };
    //console.log(requestBody)
      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/loginG', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
        const data = await response.json();
      if (data.error) {
        setMensaje('ERROR INICIO DE SESION');
        setPopupOpenError(true);
        setIsLoading(false);
      } else {

        setIsLoading(false);

        if (data.first_name !== '') {
          localStorage.setItem('email', data.email);
          localStorage.setItem('username', data.first_name);
        } else {
          localStorage.setItem('email', user.email);
          localStorage.setItem('username', user.displayName);
        }

        history.push({
          pathname: '/campaignspage',
        });
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
 };

    const buttonStyle = {
    marginBottom: 25,
    height: 50,
    fontSize:  '1.3em',
    border:'none',
    width: 200,
    color:  'white',
    backgroundColor:colors.BLACK,
    borderRadius: '20px 0px 50px 20px',
    transition: 'background-color 0.3s ease',
    cursor:'pointer'
    };

	const mobile = window.innerHeight <= 667

  return (
    <div className="home-page" style={{marginBottom:0}}>
        <div style={{backgroundImage:  `url(${Background})`, backgroundSize: 'cover', height: '100vh' }}>

        <div style={{fontFamily:'Roboto-Regular', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {isLoading && (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
                <Loader type="TailSpin" color="#000" height={80} width={80} />
                </div>
            )}
            {/*<div style={{fontFamily:'Roboto-Bold', display: 'flex', flexDirection: widthScreen ? 'column' : 'column', justifyContent: 'center', alignItems: 'center', marginTop: widthScreen ? heightScreen? '30%':'15%' : '20%' }}>
                <img src={titleLogin} alt="Logo" style={{ width: '80%' }} />
            </div>*/}
            <div style={{ textAlign: 'center',marginTop:'20px' }}>
                <img src={logo} alt="Logo" style={{ width: '30%' }} />
            </div>
            <div style={{ zIndex:1, backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            borderRadius: '50px',
                            padding: '20px',  marginTop:'-5px', width:'80%', maxWidth:'500px'}}>

                <div style={{ fontSize:mobile? '0.8em':'1em', color:'#fff', zIndex:10, letterSpacing:2, fontFamily:'Como-Bold', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-30px'}}>
                    <h2 style={{fontSize:'1.5em',color: '#fff', fontFamily:'Como-Heavy'}} >INICIO</h2>
                    <div style={{color: '#fff', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                        <span style={{letterSpacing:2, fontSize:'1.5em', fontFamily:'Como-Bold',marginTop:'-20px'}} className="titleDetail">Usuario</span>
                        <input
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{  fontSize:mobile?'1em':'1.4em',marginBottom: '3%', height:'40px', width: '250px', borderRadius: 8, border: 'none' }}
                        />
                    </div>
                    <div style={{color: '#fff', display: 'flex', flexDirection: 'column', textAlign: 'left', marginTop:'20px' }}>
                        <span style={{letterSpacing:2, fontSize:'1.5em', fontFamily:'Como-Bold'}}  className="titleDetail">Contraseña</span>
                        <input
                            type="password"
                            placeholder="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ fontSize:widthScreen?'1em':'1.4em', marginBottom: '3%', height:'40px', width: '250px', borderRadius: 8, border: 'none' }}
                        />
                    </div>

                    <button onClick={() => handleLogin(loginType, email, password,'','')} className="button-common" style={{ width:'220px',fontFamily:'Como-Bold',color:colors.WHITE, backgroundColor:colors.BLUE, marginTop:mobile?'2px':'2%',marginBottom:'3px'}}>
                        INICIAR SESIÓN
                    </button>
                    <div style={{ textAlign: 'right', marginTop: '1%',  marginBottom: '1%' }}>
                        <span
                            style={{
                            color: 'white',
                            cursor: 'pointer',
                            letterSpacing: 1,
                            fontSize: '1.1em',
                            fontFamily:'Como-Bold'
                            }}
                            onClick={handleForgotPass}
                        >
                        ¿Olvidaste la contraseña?
                        </span>
                    </div>
                </div>
            </div>
            <p style={{fontFamily:'Como-Bold', color:'#fff', fontSize:'1.2em', marginTop:'5px'}}>
                INICAR SESIÓN CON
	   	    </p>
		<div style={{ display: 'flex', justifyContent: 'center', gap: '1px' }}>
			<button onClick={handleGoogleLogin} style={{ background: 'none', border: 'none', marginTop: '-5px' }}>
				<img src={btnGoogle} alt="Logo" style={{ width: '50%' }} />
			</button>
			<button onClick={handleFacebookLogin} style={{ background: 'none', border: 'none' }}>
				<img src={btnFacebook} alt="Logo" style={{ width: '60%' }} />
			</button>
		</div>


            {/*<button onClick={handleAppleLogin}  style={{ background: 'none',border: 'none'}} >
                <img src={btnFacebook} alt="Logo" style={{ width: '60%'}} />
            </button>*/}
            <button    onClick={handleToggleRegistration} className="button-common" style={{ width:mobile?'220px':'220px',height:mobile?'35px':'50px' ,fontWeight:'bold', fontFamily:'Como-Heavy',color:colors.WHITE, backgroundColor:colors.ORANGE, marginTop:mobile?'1px':'2%'}}>
                CREAR CUENTA
            </button>
  {/* Pie de página */}

			<div style={{ bottom: '1px', position: 'fixed' }}>
                <a href="https://giveandwin.s3.amazonaws.com/POLI%CC%81TICADEPRIVACIDADGandWin.pdf" style={{ color: '#fff', marginRight: '10px' }} rel="noreferrer" target="_blank">Política de Privacidad</a>
                <a href="https://giveandwin.s3.amazonaws.com/Condiciones+del+Servicio+de+Give+and+Win.docx" style={{ color: '#fff' }} rel="noreferrer" target="_blank">Términos y Condiciones</a>
            	<p style={{ color: '#fff',fontSize: '12px' }}>© {new Date().getFullYear()} Give&Win. Todos los derechos reservados.</p>
			</div>

                    {/* Modal para restablecer la contraseña */}
              {showForgotPassModal && (
                <div
                  className="modal"
                    style={{
                    fontFamily:'Roboto-Black',
                    backgroundColor: '#fff', // Fondo blanco para el modal
                    position: 'absolute', // Posición absoluta para superponer el modal
                    top:'25%', // Alineado en la parte superior
                    width:widthScreen?'90%': '50%', // Ancho de pantalla completo
                    height: '50%', // Altura de pantalla completa
                    display: 'flex',
                    borderRadius: 10,
                    flexDirection:'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding:10,zIndex:999
                  }}
                    >
                            <span className="close" style={{cursor:'pointer', color:colors.ORANGE, fontSize:'1em', fontWeight:'bold'}} onClick={handleCloseModal}>
                            X
                            </span>
                            <h2 style={{ color:colors.ORANGE}}>Resetee su contraseña</h2>
                          <p>
                              Por favor ingrese su dirección de correo electrónico y le enviaremos un link para resetear su contraseña.
                            </p>
                            <input
                                    type="email"
                                    placeholder="email@gmail.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ marginBottom: '10px', padding: '8px', width: 300, borderRadius: 15 }}
                                />
                            <button  className="button-common" style={{ backgroundColor: colors.ORANGE, fontFamily:"Roboto-Bold"}} onClick={handleSubmit}>ENVIAR</button>
                        </div>


                    )}

                  {popupOpenError && (
                    <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '80%', backgroundColor: 'red', padding: '20px',borderRadius: '50px 0px 50px 50px',maxWidth: '300px', height: 250 }}>
                        <p style={{fontFamily:'Como-Bold', marginTop: '20%', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'white', fontSize: '1.5em' }}>{mensaje}</p>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
                        <div>
                            <button style={{
                            ...buttonStyle,
                            backgroundColor: colors.BLACK,
                            color: colors.WHITE, fontSize: '1.5em',
                            }}
                            onClick={handlePopupCloseError}
                            >
                            OK
                            </button>
                        </div>
                        </div>
                    </div>
                    </div>
                    )}

			  </div>

      </div>

    </div>
  );
};

export default Login;
