import React, { useState } from 'react';
    // eslint-disable-next-line
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line
import Header from './Header';
import { colors } from '../../utils/index';
import Loader from 'react-loader-spinner';
import logo from  '../../assets/logo.png';
import Background from '../../assets/fondoiniciosesion.png'

import igImg from '../../assets/instagram.png'

const Gracias = () => {

        // eslint-disable-next-line
    const history = useHistory();
    const heightScreen = (window.innerHeight > 680)
    const widthScreen = (window.innerWidth < 790)
// eslint-disable-next-line

    const [popupOpenError, setPopupOpenError] = useState(false);
    // eslint-disable-next-line
    const [mensaje, setMensaje] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setIsLoading(false);
  };
    const handleContinue = () => {

   window.open('https://www.instagram.com/giveandwin.ar/', '_blank');
  };

  const handleLogin = () => {
     window.open('https://www.giveandwin.com.ar/login', '_blank');
  };



  return (
    <div style={{ alignItems: 'center',  // Center content horizontally
          justifyContent: 'center', fontFamily: 'Roboto-Bold', backgroundImage: `url(${Background})`,
          height: '100vh',
          flex: 1, backgroundSize: 'cover', flexDirection: 'column', alignSelf: 'center',
          alignContent: 'center', textAlign: 'center'
      }}>
          {/*<Header showTitle="GRACIAS" />*/}


                <img src={logo} alt="Logo" style={{ width: '200px',marginTop: widthScreen ? heightScreen? '45%':'25%' : '10%'  }} />


             <h1 style={{fontFamily:'Como-Bold', color: colors.WHITE, marginBottom: '10px',fontSize:'1.2.em', marginTop:'15px', alignItems: 'center',  // Center content horizontally
            justifyContent: 'center',  }}>GRACIAS</h1>
             <h1 style={{fontFamily:'Como-Bold', color: colors.WHITE, marginBottom: '10px',fontSize:'1.2.em', marginTop:'2px' }}>{localStorage.username}</h1>
            <div style={{ display: 'flex',width:'100%',marginTop:'20px',
                            justifyContent: 'center',height: 60,
                            alignItems: 'center',textAlign:'center',}}>
              <div
                style={{
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf:'center',
                        justifyContent:'center',
                        textAlign:'center',
                        height: 60,
                        width:'350px',
                        maxWidth: '350px',
                        backgroundColor:colors.BLUE,
                        borderRadius: '0px 50px 0px 50px',
                        textDecoration: 'none',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.6)',

                  }}>
            <button style={{
                backgroundColor: 'transparent',
                cursor: 'pointer',
                border: 'none',
                textAlign: 'center',
                textDecoration: 'none',

                }}
                    onClick={handleLogin}
                >
                  <p style={{marginTop:'10px',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',fontSize: '1.5em',  color: colors.WHITE, fontFamily:'Poppins-Black',marginLeft: '10px', marginRight: '10px', height: 60, }}>
                      ¡IMPACTO GENERADO!
                      </p>
            </button>
            </div>
          </div>

        <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100vw',
                position: 'fixed',
                bottom: 0,
          }}>
                  {/*footer*/}
<div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                width: '100vw',
                maxHeight: '80px',
                justifyContent: 'center',  // Añade justifyContent para centrar horizontalmente
                alignItems: 'center',  // Añade alignItems para centrar verticalmente
                textAlign: 'center',
                            }}>

            <div style={{
                width: widthScreen? '300px':'500px',
                backgroundColor: colors.GRAY,
                borderRadius: '50px',
                fontSize: '1em',
                color: colors.WHITE,
                fontFamily: 'Como-Bold',
            }}>
                <p style={{ marginLeft: '10px', marginRight: '10px' }}>
                PARA CONOCER MÁS CAUSAS A LAS QUE APOYAMOS, CLICK ACÁ
                </p>
            </div>

            <button style={{
                backgroundColor: 'transparent',
                cursor: 'pointer',
                border: 'none',
                textAlign: 'center',
                textDecoration: 'none',

            }}
                onClick={handleContinue}
            >
                <img src={igImg} alt='botonIg' style={{ width: widthScreen ? '80%' : '80%' }} />
            </button>
            </div>



        </div>


     {isLoading && <Loader type="TailSpin" color="#fff" height={80} width={80} />}
      {popupOpenError && (
        <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '80%', backgroundColor: 'red', padding: '20px', borderRadius: 10, maxWidth: '300px', height: 250 }}>
            <p style={{ fontFamily:'Como-Bold',marginTop: '20%', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'white', fontSize: '1em', fontWeight: 'bold' }}>{mensaje}</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
              <div>
                <button style={{
                  ...buttonStyle,
                  backgroundColor: colors.BLACK,
                  color: colors.WHITE,
                }}
                  onClick={handlePopupCloseError}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
          )}



    </div>
  );
};

const buttonStyle = {
  marginBottom: 25,
  height: 60,
  fontSize: '1.3em',
  border: 'none',
  width: '100%',
  color: 'white',
  backgroundColor: colors.ORANGE,
  borderRadius: 20,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  marginTop: '10px',
};

export default Gracias;
