import { toBeRequired } from "@testing-library/jest-dom/dist/matchers";

export const colors = {
    gray : '#2e2d2c',
    degrade: 'linear-gradient(70deg, #000000, #eee)',
    image: toBeRequired,
    backcolor: '#272525',
    backbeige:'#E3CC90',
    box: 'white',
    textcolor: '#002169',
    YELLOW: '#F7B42D',
    BLACK: '#000',
    BLUE: '#20E0E0',
    GRAY: '#272525',
    WHITE: '#FFFFFF',
    GREEN: '#0A8E30',
    MODE: 'cruz',
    BLUE_HI: '#055686',
    MORADO: '#E4414B',
    DORADO: 'rgb(189,155,91)',
    LGREEN: '#a9bf59',
    GRAYL: '#d3d3d3',
    ORANGE: '#FF7D01',
    RED: '#FF2400',
    DARKGREEN: '#04BF55 '
    //'#7d9707'
}

