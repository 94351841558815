import React, { useState } from 'react';
import Header from './Header';
import { colors } from '../../utils/index';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom'
import './Main.css'
import botonContinuar from '../../assets/botones/botonContinuar.png'
import igImg from '../../assets/instagram.png'
const Donate = ({ location }) => {

    const [popupOpenError, setPopupOpenError] = useState(false);
    // eslint-disable-next-line
    const [mensaje, setMensaje] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedAmount, setSelectedAmount] = useState('1000');
    const { name, subtitle, description, image, date, prize_name, igLink } = location.state;
    let importeFalta = parseFloat(localStorage.totalCampaignNeed) - parseFloat(localStorage.totalCampaign)
    const widthScreen = window.innerWidth > 790
    const widthScreenMobile = window.innerWidth < 400
    const heightScreen = window.innerHeight < 900
   // console.log(importeFalta)
    const totalCampaignFalta = importeFalta
    if (  importeFalta > 1000) {
        importeFalta = 1000
    }
    const [customAmount, setCustomAmount] = useState(importeFalta);
            localStorage.setItem('name', name);
            localStorage.setItem('date', date);
            localStorage.setItem('prize_name', prize_name);
            localStorage.setItem('image', image);
// eslint-disable-next-line
  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setIsLoading(false);
  };
    const handleAmountSelection = (amount) => {

            localStorage.setItem('importe', amount); // Use 'amount' instead of 'customAmount'

        if (parseFloat(amount) > totalCampaignFalta) {
            setCustomAmount(0);
            setMensaje('El monto excede el total de la campaña, máximo ingreso: $' + importeFalta);
            setPopupOpenError(true);
        } else {
            setCustomAmount(amount)
            setSelectedAmount(amount);

        }


};

    const handleCustomAmountChange = (event) => {
    setPopupOpenError(false);
    setSelectedAmount(null); // Unselect any pre-selected amount when the custom amount changes

    const enteredAmount = event.target.value;

    // Regular expression to match valid positive numbers with up to two decimal places
    const isValidAmount = /^[+]?\d+(\.\d{,2})?$/.test(enteredAmount);

    if (!isValidAmount) {
        // If the entered amount is not valid, show an error message
         setCustomAmount(0);

        //setMensaje('Ingrese un monto válido.');
        //setPopupOpenError(true);
    } else {
        // If the entered amount is valid, update the state
        const formattedAmount = parseInt(enteredAmount).toString(); // Convert to number and then back to string to remove leading zeros
        setCustomAmount(formattedAmount);
        localStorage.setItem('importe',formattedAmount)
        // Check if the entered amount exceeds the campaign total
        if (parseFloat(enteredAmount) > totalCampaignFalta || enteredAmount < 0) {
            setCustomAmount(0);
            setMensaje('El monto excede el total de la campaña, máximo ingreso: $' + importeFalta);
            setPopupOpenError(true);
        }
    }
};
 const handleContinue = () => {
   let finalAmount = customAmount || selectedAmount;

  if (finalAmount) {
    // Save the final amount to localStorage
    localStorage.setItem('importe', finalAmount);

    // Navigate to the /resumen page with the final amount
    window.location.href = `/resumen/${finalAmount}`;
  } else {
    // Show error message in the popup
    setMensaje('El monto no puede ser 0. Por favor, elige un monto válido.');
    setPopupOpenError(true);
  }
  };

    const buttonStyle = {
  height: widthScreenMobile? '35px' : '50px',
  fontSize: '2em',
  border: 'none',
  width: '250px',
  color: 'white',
  fontWeight: '900',
  fontFamily:'Poppins-Black',
  backgroundColor: colors.BLUE,
  borderRadius: '30px 0px 50px 30px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  marginTop: '20px',
  marginBottom: '20px',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.6)',
};
const buttonStyleInput = {
  marginBottom: 15,
  height: widthScreenMobile? '35px' : '40px',
  fontSize: '1.5em',
  width: '20px',
  color: colors.GRAYL,
  fontFamily:'Poppins-Medium',
  backgroundColor:colors.WHITE,
  borderRadius: '0px 50px 0px 50px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  marginTop: '10px',

};


  return (
   <div style={{fontFamily:'Poppins-Medium', backgroundColor: colors.GRAY, width: '100vw', minHeight: '100vh', alignItems: 'center' }}>
      <Header showTitle="donar" />
        <div style={{ textAlign: 'center', marginTop: '70px', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <Link
                    to={{
                    pathname: `/premios/${name}`,
                    state: {
                            name: name,
                            subtitle: subtitle,
                            description: description,
                            image: localStorage.image,
                            date: date,
                            prize_name: prize_name,
                            igLink: igLink
                    },
                    }}
                    style={{  color: colors.ORANGE, fontSize: '3em', textDecoration: 'none', marginLeft: '10px', display: 'block' }}
                    >  {'<'}
              </Link>
               {/*<div style={{ display: 'flex', textAlign:'right' }}>
                    <Link to="/gracias">
                        <img src={faq} alt="Logo" style={{ width: '30%', marginRight: '10px' , marginTop:'10px'}} />
                    </Link>
                </div>*/}
        </div>
           <h2 style={{marginTop: widthScreenMobile? '-40px': '-40px', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Poppins-Black', fontSize: widthScreenMobile? '1.4em':'1.5em'}}>
                <span style={{ color: colors.WHITE, }}>¿CUÁNTO IMPACTO QUERÉS</span>
                <span style={{color:colors.ORANGE, marginLeft:10}}>GENERAR?</span>
            </h2>
              {/* Subtítulo */}
            <p style={{width:'90%', fontFamily:'Como-Medium',color:colors.WHITE, textAlign: 'center', margin: 'auto', marginBottom: '10px', fontSize: widthScreenMobile?'1em': '1.2em', marginTop:'-20px', }}>A mayor impacto, más probabilidades tenes de ganar</p>

            {/* Botones de importe */}
          <div style={{ fontWeight: 'bold', flex: 1, display: 'flex', justifyContent: 'center', marginBottom: '1px', flexDirection: 'column', textAlign: 'center', alignContent: 'center', alignItems: 'center' ,marginTop: heightScreen? '10px': '10%'}}>

            <div style={{maxWidth:'500px',
                width: '80%', border: '2px solid ' + colors.ORANGE, borderRadius: '50px 0px 50px 50px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',height:widthScreenMobile? '': '50vh',
                fontWeight: 'bold', flex: 1, display: 'flex', justifyContent: 'center', marginBottom: '5px', flexDirection: 'column', textAlign: 'center', alignContent: 'center', alignItems: 'center'}}>

                    <button
                        style={{ ...buttonStyle, backgroundColor: selectedAmount === 1000 ? colors.ORANGE : colors.BLUE }}
                        onClick={() => handleAmountSelection(1000)}
                    >
                        1.000
                    </button>
                    <button
                        style={{ ...buttonStyle, backgroundColor: selectedAmount === 5000 ? colors.ORANGE : colors.BLUE }}
                        onClick={() => handleAmountSelection(5000)}
                    >
                        5.000
                    </button>
                    <button
                        style={{ ...buttonStyle, backgroundColor: selectedAmount === 10000 ? colors.ORANGE : colors.BLUE }}
                        onClick={() => handleAmountSelection(10000)}
                    >
                        10.000
              </button>

             </div>

                {/* Input de importe personalizado */}
           <div style={{textAlign:'left', fontSize: '1.3em',fontFamily:'Poppins-Black', color:colors.WHITE, position: 'relative', marginTop:'5px', fontWeight:'bold',fontStyle:'italic' }}>TU IMPACTO
                <span style={{  fontFamily:'Poppins-Medium',color:colors.GRAY, position: 'absolute', left: '40px', top: '60%', transform: 'translateY(-50%)', fontSize: '1.3em', textAlign:'center'}}>$</span>
                <input
                    type="number"
                    placeholder="Ingrese un monto personalizado"
                    value={customAmount}
                    onChange={handleCustomAmountChange}
                    style={{ ...buttonStyleInput, paddingLeft: '25px', marginBottom: '10px', fontSize: '1.3em', color: '#000', textAlign: 'center' , width: '250px',maxWidth:'350px' }}
                />
            </div>
              <Link to={{ pathname: `/resumen/${selectedAmount || customAmount}`
                }}>
            <button
                    onClick={handleContinue}
                    className="button-common"
                    style={{ textDecoration:'none',backgroundColor:'transparent', marginTop: '5px', fontWeight: 'bold', width: '200px' }}
                    disabled={(selectedAmount === null || selectedAmount === 0) && (customAmount === null || parseInt(customAmount) === 0)}
                >
                     <img
                        key={name}
                        src={botonContinuar}  // Reemplaza 'ruta_de_tu_imagen' con la ruta de tu imagen
                        alt="Texto alternativo"
                        style={{
                        height: 'auto',
                        width:  widthScreenMobile? '200px' :'250px',
                        cursor: 'pointer',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        }}
            />
                  </button>
                  </Link>

           <div style={{flex:1, display:'flex', flexDirection:'row', width:'100vw',textAlign:'center', justifyContent:'center', bottom:0,
                   }}>
                    <div style={{width:'300px',
                    fontSize: '1em', color: colors.WHITE,
                    fontFamily:'Como-Bold', justifyContent:'center', textAlign:'center', alignItems:'center', alignSelf:'center', alignContent:'center', cursor:'pointer'
                        }}>
                        <a  href={igLink} target="_blank" rel="noopener noreferrer" style={{marginLeft:'10px',marginRight: '5px', textDecoration:'none', color:'#fff' , cursor:'pointer'}}>
                            PARA CONOCER MÁS CAUSAS A LAS QUE APOYAMOS, CLICK ACÁ
                        </a>

                    </div>
                   <a href={igLink} target="_blank" rel="noopener noreferrer">
                        <img src={igImg} alt={name} style={{ width: widthScreen ? '60%' : '60%',  margin: '10px' }} />
                    </a>
                </div>



    </div>

     {isLoading && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
          <Loader type="TailSpin" color="#000" height={80} width={80} />
        </div>
      )}
      {popupOpenError && (
        <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '80%', backgroundColor: 'red', padding: '20px',borderRadius: '50px 0px 50px 50px',maxWidth: '300px', height: 250 }}>
            <p style={{fontFamily:'Como-Bold', marginTop: '20%', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'white', fontSize: '1.5em' }}>{mensaje}</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
              <div>
                <button style={{
                  ...buttonStyle,
                  backgroundColor: colors.BLACK,
                  color: colors.WHITE, fontSize: '1.5em',   borderRadius: '50px 0px 50px 0px',
                }}
                  onClick={handlePopupCloseError}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
          )}



    </div>
  );
};




export default Donate;